import { sectionUpdateDTO } from "./../MainProject/MainProjectSection";
import Section from "../ModelSection";

export default class SectionToUpdate {
  section: Section | null = null;

  constructor (sec: Section) {
    this.section = sec;
  }

  FormatToUpdate (): sectionUpdateDTO | null {
    if (this.section && this.section.id) {
      return {
        id: this.section.id,
        projectId: this.section.projectId,

        description: this.section.description,
        title: this.section.title,

        direction: this.section.direction,
        kilometrage: this.section.kilometrage,

        mainProjectOrdinalNumber: this.section.mainProjectOrdinalNumber,
        ordinalNumber: this.section.ordinalNumber,
        visible: this.section.visible,

        projectSectionTypeId: this.section.type?.id || 0,
        backgroundImage: this.section.backgroundImage,

        mainboardOneSerial: this.section.boardOne,
        displayOneTypeId: this.section.displayOneType?.id || null,
        cbRadioEnabledOne: this.section.cbRadioEnabledOne,

        mainboardTwoSerial: this.section.boardTwo,
        displayTwoTypeId: this.section.displayTwoType?.id || null,
        cbRadioEnabledTwo: this.section.cbRadioEnabledTwo,

        radarOnLeftSide: this.section.radarOnLeftSide,

        deviceOneBatteryNumber: this.section.deviceOneBatteryNumber,
        deviceTwoBatteryNumber: this.section.deviceTwoBatteryNumber,
        deviceOneSolarPanelNumber: this.section.deviceOneSolarPanelNumber,
        deviceTwoSolarPanelNumber: this.section.deviceTwoSolarPanelNumber,
      };
    }
    return null;
  }
}
