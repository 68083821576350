




































/**
 * Users tab view Get all the users,
 * in the system for the add  member in the user list,
 * get all the users in the project.
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 * @Todo [ ] We need a endpoint for all the user information in the project.
 */

import Vue from "vue";
import { Component } from "vue-property-decorator";

import { mapGetters, mapActions } from "vuex";

// @Modal
import ModalAddMember from "@/components/project/SingleProject/Users/ModalAddMember.vue";
import MembersListTable from "@/components/project/SingleProject/Users/MembersListTable.vue";
import UserAccess from "@/model/User/UserAccess";
import Project from "@/model/Project";

// ! Components
@Component({
  name: "UserTabView",
  components: {
    ModalAddMember,
    MembersListTable
  },
  computed: {
    ...mapGetters(["getUserAccess"]),
    ...mapGetters("projects", {
      GetAllUsersInTheProject: "GetAllUserOption",
      CurrentProject: "GetCurrentProject"
    })
  },
  methods: {
    ...mapActions("projects", {
      getAllTheUserInTheCurrentProject: "GetAllUsersFromTheProject",
      GetCurrentProject: "DetailsOfProject",
      GetCurrentProjectDetails: "DetailsOfProject"
    })
  }
})
export default class UserTabView extends Vue {
  /*****  Vuex ******/
  readonly getUserAccess!: UserAccess;
  readonly GetAllUsersInTheProject!: [object] | null;
  private getAllTheUserInTheCurrentProject!: Function;
  private GetCurrentProjectDetails!: Function;
  GetCurrentProject!: Function;
  readonly CurrentProject!: Project;
  /*****  LocalState ******/

  /*****  HelperFunction ******/
  async getTheUserListAndTheCurrentUsersInTheProject() {
    this.getAllTheUserInTheCurrentProject();
    this.GetCurrentProjectDetails(this.$route.params.id);
  }

  emitUpdateStatus(status: object) {
    this.$emit("updateStatus", status);
  }

  /*----------  vue life cycles  ----------*/
  async created() {
    await this.getTheUserListAndTheCurrentUsersInTheProject();
  }
}
