<script>
/**
 * Description :_
 * When the user is not authorized to view the details of the current project  or any other resousrces that he is trying to access
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 *
 */
export default {};
</script>

<template funtional>
  <b-card class="border-0 shadow" variant="dark" body-class="p-5">
    <b-card-title>
      {{ $t("project.access.denied.title") }}
    </b-card-title>
    <b-card-text class="py-5">
      <p>
        {{ $t("project.access.denied.content") }}
      </p>
      <b-button variant="secondary" @click="$router.go(-1)">{{ $t("utils.page.goBack") }}</b-button>
    </b-card-text>
  </b-card>
</template>
<style scoped></style>
