






















































/**
 * Modal to add a new member in to the project,
 * current version get all the users from the usersList end point.
 *
 *
 *
 * @author Reflect-Media <ig@reflect-Media.com>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 *
 */

import Vue from "vue";
import Component from "vue-class-component";
import { mapGetters, mapActions } from "vuex";

import Multiselect from "vue-multiselect";
import Project from "@/model/Project";

//!Interface
interface UpdateStatus {
  class: string;
  msg: string;
}
interface userModel {
  username: string;
  id: string;
  uid: string;
}
// ! Components
@Component({
  name: "ModalAddMember",
  components: {
    Multiselect
  },
  computed: {
    ...mapGetters("projects", {
      userListGetNotAdminUsers: "GetAllUserOption",
      CurrentProject: "GetCurrentProject"
    })
  },
  methods: {
    ...mapActions("projects", {
      UpdateProject: "UpdateCurrentProject",
      GetCurrentProject: "DetailsOfProject"
    })
  }
})
export default class ModalAddMember extends Vue {
  /*****  Vuex ******/
  readonly userListGetNotAdminUsers!: Array<userModel>;
  readonly CurrentProject!: Project;

  UpdateProject!: Function;
  GetCurrentProject!: Function;
  //UpdateTheProject

  /*****  LocalState ******/
  updateStatus: UpdateStatus | null = null;
  usersSelected: [] = [];
  loading = false;
  /*****  vue life cycles ******/

  /*****  HelperFunction ******/
  confirmModal(msg: string, action: Function) {
    this.$bvModal
      .msgBoxConfirm(msg, {
        title: (this as any).$t("projects.singleProjectView.confirmationModal.title"),
        size: "md",
        buttonSize: "md",
        okVariant: "success",
        headerClass: "p-2 border-bottom-0 bg-light",
        footerClass: "p-2 border-top-0",
        centered: true,
        okTitle: (this as any).$t("utils.alertBox.ok"),
        cancelVariant: "light",
        cancelTitle: (this as any).$t("utils.alertBox.cancel")
      })
      .then((value: boolean) => {
        action(value);
      });
  }
  /*****  Methods ******/

  handleAddUserToTheProject() {
    this.updateStatus = null;
    this.confirmModal((this as any).$t("project.users.confirmAdd"), async (value: boolean) => {
      this.$emit("updateStatus", null);
      if (value) {
        this.loading = true;
        let memberId: Array<string> = this.usersSelected.map((user: userModel): string => {
          let id = user.id;
          return id;
        });
        const oldUsersId = this.CurrentProject.users.map((user: any) => user.uid);
        const allUserUniqueUsersProject: Array<string> = Array.from(new Set([...oldUsersId, ...memberId]));

        let UpdateProject = this.CurrentProject.formatToUpdate();
        UpdateProject.users = allUserUniqueUsersProject;

        let updateProjectStatus = await this.UpdateProject(UpdateProject);

        if (updateProjectStatus.status === 200) {
          this.usersSelected = [];
          this.$emit("updateStatus", {
            class: "success brand-green",
            msg: this.$t("project.users.userAddedSuccess")
          });
          this.GetProjectInformationLatest();
          this.$bvModal.hide("addMemberToProject");
        } else {
          this.updateStatus = {
            class: "danger",
            msg: updateProjectStatus.response.data.message
          };
        }
        this.loading = false;
      }
      return;
    });
  }

  GetProjectInformationLatest() {
    this.GetCurrentProject(this.CurrentProject.id);
    this.updateStatus = null;
  }
}
