
/**
 * Table whit all the member in the project,
 *format information of the member.
 *Handle the remove of a member in the project and update the members in the project.
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 * @Todo [ ] Get all the members information and display in the table.
 */

import { Component, Watch, Mixins } from "vue-property-decorator";
import { mapGetters, mapActions } from "vuex";
import confirmModal from "@/mixins/confirmModal/confirmModal";
import Project from "@/model/Project";
import ModelUser from "@/model/ModelUser";

//!Interface
interface Status {
  class: string;
  msg: string;
}
interface User {
  id: string;
  username: string;
  uid?: string;
}

@Component({
  name: "MembersListTable",
  computed: {
    ...mapGetters("projects", {
      CurrentProject: "GetCurrentProject"
    })
  },
  methods: {
    ...mapActions("projects", {
      UpdateProject: "UpdateCurrentProject",
      GetCurrentProject: "DetailsOfProject"
    })
  }
})
export default class MembersListTable extends Mixins(confirmModal) {
  /*----------  Vuex   ----------*/
  readonly CurrentProject!: Project;
  private UpdateProject!: Function;
  private GetCurrentProject!: Function;

  /*----------  Local state  ----------*/
  updateStatus: null | Status = null;
  currentListOfMemberInTheProject: Array<ModelUser> = [];
  tableIsBusy: boolean = false;
  labelsForMembersList: Array<string> = ["username", "roles", "firstName", "lastName", "email", "phone", "actions"];

  /*----------  Watcher  ----------*/

  @Watch("CurrentProject", { immediate: false, deep: false })
  async handleTheProjectChanges(val: Project) {
    if (val.getDetailsOfAllUsers) {
      try {
        this.tableIsBusy = true;
        this.currentListOfMemberInTheProject = await val.getDetailsOfAllUsers();
        this.tableIsBusy = false;
      } catch (error) {
        this.tableIsBusy = true;
      }
    }
  }

  /*----------  Methods  ----------*/
  handleUserRoleTranslation(role: string | null) {
    if (!role) return;

    let haveTranslations = role in (this as any).$t("roles.labels");
    if (haveTranslations) return (this as any).$t(`roles.labels.${role}`);
    else return role;
  }
  removeUserFromProject(user: User) {
    this.updateStatus = null;
    this.$emit("updateStatus", null);
    this.confirm(
      (this as any).$t("projects.singleProjectView.confirmationModal.body", {
        name: user.username
      }),
      async () => {
        try {
          let newUsersList = this.CurrentProject.users
            .map((user: any) => {
              let id = user.uid;
              return id;
            })
            .filter((id: string) => id !== user.id);

          let projectUsersUpdated = Array.from(new Set([...newUsersList]));
          let formatedProject = this.CurrentProject.formatToUpdate();
          formatedProject.users = projectUsersUpdated;
          let updateUsersInProjectStatus = await this.UpdateProject(formatedProject);
          if (updateUsersInProjectStatus.status === 200) {
            this.GetCurrentProject(this.CurrentProject.id);
            this.$emit("updateStatus", {
              class: "success",
              msg: (this as any).$t("projects.singleProjectView.statusMsg.removeUserSuccess")
            });
          } else {
            this.$emit("updateStatus", {
              class: "success",
              msg: updateUsersInProjectStatus
            });
          }
        } catch (error) {
          this.$emit("updateStatus", {
            class: "success",
            msg: error
          });
        }
      }
    );
  }
}
