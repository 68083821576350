<script>
/**
 * Dashboard card container regularly use to show the donut chart or but it can recive other slot to render other uimportant
 * information about the services
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

export default {
  props:["contentLabel"]
}
</script>

<template functional>
    <div class="ribbon__wrapper"  >
      <div class="ribbon__content">
        {{props.contentLabel}}
      </div>
    </div>
</template>
<style scoped lang="scss">
.ribbon {
  &__wrapper {
    left: 0px;
    position: absolute;
    top: 0px;
    height: 100px;
    width: 100px;
    overflow: hidden;
  }
  &__content {
    left: -64px;
    position: absolute;
    top: 32px;

    height: 24px;
    width: 206px;
    transform: rotate(-45deg);
    background-color: var(--gray);
    text-align: center;
    z-index: 2;
    color: var(--dark);
  }
}
</style>
